import { useEffect } from 'react';
import { firebaseAnalytics } from './firebase';
import { logEvent } from 'firebase/analytics';
import LandingPage from './LandingPage';

function App() {
  useEffect(() => {
    logEvent(firebaseAnalytics, `landing_page_visited`);
  }, []);

  return (
    <LandingPage />
  );
}

export default App;
