// src/LandingPage.js
import React from "react";
import "./index.css"; 
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  
  faBars, 
  faCheck, 
  faWandMagicSparkles, 
  faHeart, 
  faShieldHalved, 
  faLocationDot 
} from '@fortawesome/free-solid-svg-icons';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import MyPaycheckCalculatorPreview from './resources/MyPaycheckCalculatorScreenshots.png';

// Fade-up variants
const fadeUpVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.6, ease: "easeOut" },
  },
};

const LandingPage = () => {
  return (
    <div className="h-full text-base-content min-h-screen bg-white">
      {/* Header */}
      <header
        id="header"
        className="fixed w-full bg-white/95 backdrop-blur-sm z-50 border-b border-gray-100"
      >
        <div className="container mx-auto px-6">
          <div className="flex items-center justify-between h-20">
            {/* Logo */}
            <div className="flex items-center space-x-2">
              <span className="text-xl font-semibold">Bird Software LLC</span>
            </div>

            {/* Desktop Nav */}
            <nav className="hidden md:flex space-x-8">
              <a href="#apps" className="text-gray-600 hover:text-blue-600">
                Apps
              </a>
              <a href="#about" className="text-gray-600 hover:text-blue-600">
                About
              </a>
              <a href="#contact" className="text-gray-600 hover:text-blue-600">
                Contact
              </a>
            </nav>

            {/* Mobile Nav Button */}
            <button className="md:hidden">
              <FontAwesomeIcon icon={faBars} className="text-2xl" />
            </button>
          </div>
        </div>
      </header>

      {/* Hero Section */}
      <motion.section
        id="hero"
        className="pt-32 pb-20 px-6"
        variants={fadeUpVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
      >
        <div className="container mx-auto text-center">
          <motion.h1
            className="text-5xl md:text-6xl font-bold mb-6"
            variants={fadeUpVariants}
          >
            Everyday Tasks Simplified
          </motion.h1>
          <motion.p
            className="text-xl text-gray-600 max-w-2xl mx-auto mb-12"
            variants={fadeUpVariants}
          >
            We create intuitive iOS applications that make your everyday tasks
            easier and more efficient.
          </motion.p>
          <motion.div
            className="flex justify-center"
            variants={fadeUpVariants}
          >
            <a
              href="#apps"
              className="bg-blue-600 text-white px-8 py-4 rounded-lg text-lg font-semibold hover:bg-blue-700 transition-colors"
            >
              Explore Our Apps
            </a>
          </motion.div>
        </div>
      </motion.section>

      {/* Apps Section */}
      <motion.section
        id="apps"
        className="py-20 bg-gray-50"
        variants={fadeUpVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
      >
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-16">Our Apps</h2>
          <div className="max-w-3xl mx-auto">
            <motion.div
              id="app-card"
              className="bg-white rounded-2xl shadow-lg overflow-hidden"
              variants={fadeUpVariants}
            >
              <div className="flex flex-col md:flex-row">
                {/* Left side: Text */}
                <div className="md:w-1/2 p-8">
                  <h3 className="text-2xl font-bold mb-4">
                    My Paycheck Calculator
                  </h3>
                  <p className="text-gray-600 mb-6">
                    Estimate your take-home pay accurately with our simple yet
                    powerful paycheck calculator. Perfect for employees and
                    freelancers.
                  </p>
                  <div className="space-y-3">
                    <div className="flex items-center">
                      <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-3" />
                      <span>Federal & 50 state income tax estimates</span>
                    </div>
                    <div className="flex items-center">
                      <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-3" />
                      <span>Visualize your paycheck and tax brackets</span>
                    </div>
                    <div className="flex items-center">
                      <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-3" />
                      <span>Compare tax scenarios side-by-side</span>
                    </div>
                  </div>
                  <div className="mt-8">
                    <a
                      href="https://apps.apple.com/us/app/my-paycheck-calculator/id6449910460"
                      className="inline-flex items-center space-x-2 bg-black text-white px-6 py-3 rounded-lg"
                    >
                      <FontAwesomeIcon icon={faApple} className="text-xl" />
                      <span>Download on the App Store</span>
                    </a>
                  </div>
                </div>

                {/* Right side: Image */}
                <div className="md:w-1/2 bg-gray-100 p-8 flex items-center justify-center">
                  <img
                    className="w-64 rounded-2xl shadow-lg"
                    src={MyPaycheckCalculatorPreview}
                    alt="modern iOS app interface showing paycheck calculator with blue and white color scheme, minimal design"
                  />
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.section>

      {/* About Section */}
      <motion.section
        id="about"
        className="py-20"
        variants={fadeUpVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
      >
        <div className="container mx-auto px-6">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-6">About Bird Software</h2>
            <p className="text-gray-600 mb-12">
              Founded in 2024, Bird Software LLC is dedicated to creating
              high-quality iOS applications that solve real-world problems. Our
              focus is on delivering intuitive user experiences and reliable
              performance.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <motion.div
                id="value-1"
                className="p-6 bg-gray-50 rounded-xl"
                variants={fadeUpVariants}
              >
                <FontAwesomeIcon icon={faWandMagicSparkles} className="text-blue-600 text-3xl mb-4" />
                <h3 className="text-xl font-semibold mb-2">Innovation</h3>
                <p className="text-gray-600">
                  Creating unique solutions for everyday challenges
                </p>
              </motion.div>
              <motion.div
                id="value-2"
                className="p-6 bg-gray-50 rounded-xl"
                variants={fadeUpVariants}
              >
                <FontAwesomeIcon icon={faHeart} className="text-blue-600 text-3xl mb-4" />
                <h3 className="text-xl font-semibold mb-2">User-Focused</h3>
                <p className="text-gray-600">Designing with our users in mind</p>
              </motion.div>
              <motion.div
                id="value-3"
                className="p-6 bg-gray-50 rounded-xl"
                variants={fadeUpVariants}
              >
                <FontAwesomeIcon icon={faShieldHalved} className="text-blue-600 text-3xl mb-4" />
                <h3 className="text-xl font-semibold mb-2">Reliability</h3>
                <p className="text-gray-600">
                  Building stable and secure applications
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </motion.section>

      {/* Footer */}
      <footer id="footer" className="bg-gray-900 text-white py-12">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div id="footer-brand" className="col-span-2">
              <div className="flex items-center space-x-2 mb-4">
                <span className="text-xl font-semibold">Bird Software LLC</span>
              </div>
              <p className="text-gray-400">
                Creating innovative iOS applications that make a difference in
                people's lives.
              </p>
            </div>
            <div id="footer-links">
              <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
              <ul className="space-y-2">
                <li>
                  <a href="#apps" className="text-gray-400 hover:text-white">
                    Apps
                  </a>
                </li>
                <li>
                  <a href="#about" className="text-gray-400 hover:text-white">
                    About
                  </a>
                </li>
                <li>
                  <a href="#contact" className="text-gray-400 hover:text-white">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
            <div id="footer-contact">
              <h4 className="text-lg font-semibold mb-4">Contact</h4>
              <ul className="space-y-2">
                <li className="flex items-center space-x-2">
                  <FontAwesomeIcon icon={faEnvelope} className="text-gray-400" />
                  <a
                    href="mailto:info@birdsoftwarellc.com"
                    className="text-gray-400 hover:text-white"
                  >
                    info@birdsoftwarellc.com
                  </a>
                </li>
                <li className="flex items-center space-x-2">
                  <FontAwesomeIcon icon={faLocationDot} className="text-gray-400" />
                  <span className="text-gray-400">
                    1942 Broadway St. STE 314C, Boulder, Colorado 80302
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="border-t border-gray-800 mt-12 pt-8 text-center text-gray-400">
            <p>&copy; 2025 Bird Software LLC. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
